import { Condition } from '@digital-motors-boatyard/common/dist/enums';
import { DealSheetVehicle } from '@digital-motors-boatyard/common/dist/interfaces/entity/dealsheet/DealSheetVehicle.interface';
import { rgba } from '@digital-motors-boatyard/ui.helpers';
import { Text } from '@digital-motors-boatyard/ui.text';
import {
  borderRadius,
  spacing,
  styled,
} from '@digital-motors-boatyard/ui.theme';
import { FC } from 'react';

import {
  RIDER_HEADER_HEIGHT,
  RIDER_WIDTH,
  UNIT_IMAGE_DEFAULT,
} from '../constants';
import { color } from '../lib/color';

const Container = styled.header<{ infoOnly?: boolean }>`
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-color: linear-gradient(
      200deg,
      ${(props) => rgba(color('background', 'dark')(props), 0.4)},
      ${(props) => rgba(color('background', 'dark')(props), 0.6)}
    ),
    ${color('background')};
  position: relative;

  :after {
    ${(props) => {
      return `
        background: linear-gradient(
          180deg,
          ${rgba(color('background', 'dark')(props), 0)},
          ${rgba(color('background', 'dark')(props), 0.3)},
          ${rgba(color('background', 'dark')(props), 0.5)}
        );
      `;
    }}
    content: '';
    height: 75%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  ${(props) => {
    if (props.infoOnly) {
      return `
        border-radius: ${borderRadius.s};
        margin: 0;
        min-height: ${spacing.xxl};
        position: relative;
        overflow: hidden;

        :after {
          border-radius: 0 0 ${borderRadius.s} ${borderRadius.s};
        }
      `;
    }
    return `
      min-height: ${RIDER_HEADER_HEIGHT}px;
      position: sticky;
      right: 0;
      top: -${RIDER_HEADER_HEIGHT - 110}px;
      z-index: 8;
      transition: min-height 300ms ease-in-out;

      @media screen and (min-width: 600px) {
        max-width: ${RIDER_WIDTH}px;
      }
    `;
  }}
`;

const Content = styled.div<{ infoOnly?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: ${(props) => spacing.units(props.infoOnly ? 4 : 6)} ${spacing.xxs};
  z-index: 3;

  @media screen and (min-width: 600px) {
    max-width: ${RIDER_WIDTH}px;
    padding: ${(props) => spacing.units(props.infoOnly ? 4 : 6)} ${spacing.xs};
  }
`;

const SmallText = styled(Text)`
  color: ${color('regular', 'dark')};
  text-align: center;
  margin: 0;
`;
SmallText.defaultProps = {
  as: 'p',
  variant: 'small',
  bold: true,
};

const NameText = styled(Text)`
  color: ${color('regular', 'dark')};
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    font-size: 1.25em;
  }
`;
NameText.defaultProps = {
  as: 'h1',
  variant: 'heading5',
};

export interface Props
  extends Pick<
    DealSheetVehicle,
    'imageUrl' | 'condition' | 'year' | 'make' | 'model'
  > {
  infoOnly?: boolean;
}

const VehicleImage: FC<Props> = ({
  infoOnly,
  imageUrl,
  condition,
  year,
  make,
  model,
}) => {
  return (
    <Container
      data-testid="Header__Container"
      infoOnly={infoOnly}
      style={
        imageUrl
          ? { backgroundImage: `url(${imageUrl})` }
          : {
              backgroundImage: `url(${UNIT_IMAGE_DEFAULT})`,
            }
      }
    >
      <Content infoOnly={infoOnly}>
        <SmallText>
          {condition === Condition.NEW && 'New'}
          {condition === Condition.USED && 'Preowned'}
          {` ${year} ${make}`}
        </SmallText>
        <NameText>{model}</NameText>
      </Content>
    </Container>
  );
};

export default VehicleImage;
